.side_bar_overlay {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  z-index: 101;
  top: 0;
}
.side_bar_canvas {
  position: fixed;
  top: 0;
  z-index: 102;
  width: 300px;
  height: 100vh;
  background-color: #fff;
  padding: 20px;
  box-shadow: 2px 0px 1px #00000036;
}
.side_bar_canvas.dark {
  background-color: #000;
  color: #fff;
}
.header_side_bar {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}

.list_menu {
  height: calc(100vh - 200px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 20px;
}
.search_result_items::-webkit-scrollbar,
.list_menu::-webkit-scrollbar {
  width: 1px;
}
.nav_menu ul {
  list-style: none;
  width: 100%;
}
.nav_menu ul li {
  position: relative;
  width: 100%;
  font-size: 1.2rem;
  margin: 10px 0;
  cursor: pointer;
  font-family: var(--neue-reg-font);
  cursor: pointer;
}

ul.sub_nav_inner li {
  font-size: 1rem;
  font-family: var(--neuzeit-light-font);
  font-weight: 100;
}
.seacr_section {
  height: calc(100vh - 250px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 10px;
}
.sub_title {
  font-family: var(--neuzeit-reg-font);
  margin-bottom: 5px;
}
.sub_menu div:has(.menu) {
  margin: 3px 0;
}
.input_search_section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  align-items: flex-start;
}
.search_input {
  width: 100%;
}
.search_input.dark input {
  border-bottom: 1px solid #fff;
  color: #fff;
}

.search_input input::placeholder {
  opacity: 1;
}
.close_search {
  position: absolute;
}
.input_search_section input {
  width: 80%;
  font-size: 0.8rem;
}
.search_icon_input {
  position: absolute;
  right: 0;
  top: 0;
}
.close_icon.dark {
  color: #fff;
}
.search_section {
  margin-top: 20px;
  width: 80%;
}
.search_trending {
  font-size: 0.9rem;
  color: #3a3a3a;
}
.top_trending_category {
  display: flex;
  justify-content: space-between;
}
.trending_category {
  font-size: 0.9rem;
  margin-top: 10px;
}
.footer_side_bar {
  position: fixed;
  bottom: 0;
  text-transform: uppercase;
}
.top_search {
  margin-top: 40px;
  font-size: 0.9rem;
  color: #3a3a3a;
}
.top_search_items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.top_search_items img {
  margin-top: 10px;
  cursor: pointer;
}
.search_result {
  background-color: #fff;
  width: 100%;
  margin-top: 30px;
}
.search_result.dark {
  background-color: #000;
  color: #fff;
}
.search_result_title {
  color: #3a3a3a;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.search_result_title.dark,
.search_trending.dark,
.top_search.dark {
  color: #fff;
}
.search_result_items {
  background-color: #fff;
  /* height: 170px; */
  height: calc(100vh - 250px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.search_result_items.dark {
  background-color: #000;
}
.account {
  font-size: 1.2rem;
  padding: 20px 0;
}

.badge {
  position: absolute;
  top: -6px;
  right: -30px;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
  animation: blink 2s ease-in-out infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.separator {
  border-top: 0.8px solid #b3b3b3;
  border-bottom: 0.8px solid #b3b3b3;
  /* margin: 10px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.join_affiliate {
  font-size: 1.2rem;
  font-family: var(--neue-reg-font);
  /* animation: blink 1.6s ease-in-out infinite; */
  padding: 0 10px;
  background-color: #e8ddec;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
