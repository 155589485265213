.menu_canvas {
  width: 100%;
  background-color: #fff;
  padding: 15px 80px 65px 80px;
  position: absolute;
  z-index: 5;
  /* top: 18px; */
}

.menu_canvas.dark {
  background-color: #000;
  color: #fff;
}
.hr {
  margin-bottom: 50px;
  margin-top: 0px;
}
.main_menu {
  display: flex;
}

.main_menu_shop {
  display: flex;
  height: 17vw;
}

.listMenu {
  width: 50%;
  display: flex;
}

.menuImage {
  width: 50%;
  display: flex;
}

.menuImage img {
  width: 100% !important;
  height: auto !important;
  object-fit: contain;
}

.fineImage {
  width: auto;
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sub_menu {
  margin-right: 80px;
}
.sub_title {
  font-family: var(--neuzeit-reg-font);
  margin-bottom: 10px;
}
.menu {
  font-family: var(--neuzeit-light-font);
  margin: 5px 0;
  width: fit-content;
  position: relative;
}

.new {
  position: absolute;
  top: -5px; /* naik ke atas */
  right: -25px; /* geser ke kanan */
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 4px;
  animation: blink 2s ease-in-out infinite;
  font-weight: bold;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1480px) {
  .listMenu {
    width: 70%;
    display: flex;
  }

  .menuImage {
    width: 30%;
    display: flex;
  }

  .menu_canvas {
    padding-bottom: 140px;
  }
}

@media only screen and (min-height: 1000px) {
  .menu_canvas {
    padding-bottom: 120px;
  }

  .sub_menu {
    margin-right: 0px !important;
    padding-right: 60px;
  }
}
